// Import CSS
import styles from '../css/app.pcss';

// import "node_modules/@fancyapps/ui/dist/fancybox.css";
// import '../css/modules/fancybox.pcss';
/* ------------------------------------------------
4X:
Accessibility Library
------------------------------------------------*/
// import whatsock4x from './libs/4X/4X.Max.js';

/* ------------------------------------------------
CORE:
Load any critical scripts first, including functionality
that exists on every page.
------------------------------------------------*/
import globalEvents from './modules/globals/events.js';
import accessibility from './modules/globals/accessibility.js';
import animations from './modules/globals/animations.js';
import burgerMenu from './modules/globals/hamburger_menu.js';


// Lazy Load images
import LazyLoad from "vanilla-lazyload";
const lazyContent = new LazyLoad({});

// Social Nav Dropdown
let socialBtn = document.getElementById('social-dropdown-btn');
let socialNav = document.getElementById('social-dropdown-nav');

socialBtn.addEventListener('mouseover', (e) => {
    socialNav.classList.remove('hidden');
});

socialBtn.addEventListener('mouseout', (e) => {
    socialNav.classList.add('hidden');
});

// Form display toggle
let nominationCheckSubmit = document.getElementById('nomination-certify-verification-submit');
let verificationError = document.getElementById('verification-error');
let nominationCheck = document.getElementById('nomination-certify-verification');
let passwordWrapper = document.getElementById('password_check_wrapper');
let nominationForm = document.getElementById('nomination_form');

if(nominationCheck){
    if(getCookie('brfnominate')){
        passwordWrapper.classList.add('hidden');
        verificationError.classList.add('hidden');
        nominationForm.classList.remove('hidden');
    }
    else{
        nominationCheckSubmit.addEventListener('click', function(){
            // Success
            if((nominationForm && nominationCheck.value === "BRFnominate")){
                passwordWrapper.classList.add('hidden');
                verificationError.classList.add('hidden');
                nominationForm.classList.remove('hidden');
                document.cookie = 'brfnominate=true;';
            }
            // Incorrect
            else{
                passwordWrapper.classList.remove('hidden');
                verificationError.classList.remove('hidden');
                nominationForm.classList.add('hidden');
            }
        });
    }
}

function getCookie (name) {
    let value = `; ${document.cookie}`;
    let parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

// $('.gform_button').wrap('<div class="gform_button-wrapper"></div>');

/* Check for Form error, if so scroll */
let formErrorField = document.getElementsByClassName('gform_validation_errors');
let formConfirmation = document.getElementsByClassName('gform_confirmation_wrapper');

if(formErrorField.length > 0){
    window.setTimeout(function(){
        if(formErrorField[0]){
            formErrorField[0].scrollIntoView({ behavior: "smooth" });
        }
    }, 400);
}

if(formConfirmation.length > 0){
    window.setTimeout(function(){
        if(formConfirmation[0]){
            formConfirmation[0].scrollIntoView({ behavior: "smooth" });
        }
    }, 400);
}

$('.expand').click(function(){
    $(this).addClass('hidden');
    $(this).prev().removeClass('expandable expandable-short');
});

$('.toggle-board').click(function(){
    $(this).children('span').toggleClass('rotate-90');
    $(this).children('span').toggleClass('-rotate-90');

    $(this).parent().next().toggleClass('hidden');
    $(this).parent().parent().next().toggleClass('hidden');
});


/* ==========================================================================
========================================================================== */

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
