import { hideHeader, showHeader, hideBackToTop, showBackToTop  } from './tools.js';

/* --------------------------------------------------------------------
Hide header bar on scroll.
Reveal after period of inactivity.
-------------------------------------------------------------------- */
let hideHeaderTriggerPoint = 100;
var isWaiting, lastScrollPos;

window.addEventListener('scroll', function(){
    // Clear our timeout throughout the scroll
    window.clearTimeout( isWaiting )

    // Back To Top
    if(getYPosition() > 0 && (getYPosition() > hideHeaderTriggerPoint)){
        showBackToTop();
    }
    else{
        hideBackToTop();
    }

    if(getYPosition() > 0 && (getYPosition() > lastScrollPos)){
        hideHeader();
    }
    else{
        showHeader();
    }

    lastScrollPos = getYPosition();
});


function getYPosition(){
    var top  = window.pageYOffset || document.documentElement.scrollTop
    return top;
}